<template>
     <p id="demo"></p>
</template>
<script>
export default {
   
  name: 'RedirectComponent',
  mounted() {
    var str = window.navigator.userAgent;
    var str1 = "https://vimass.vn/"; // Default URL

    if (str.indexOf("Android") != -1) {
      str1 = "https://play.google.com/store/apps/details?id=com.qr.vcard&hl=vi";
      //https://play.google.com/store/apps/details?id=com.qr.vcard&pcampaignid=web_share
    } else if (str.indexOf("iPhone") != -1) {
      str1 = "https://apps.apple.com/vn/app/vimass-wallet/id1565903620?l=vi";
    }
    
    window.location.href = str1;
    console.log(str);
  }
}

</script>