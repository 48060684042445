<template>
   <!-- <nav class="nav_center2" style="padding: 0; background: cadetblue">
    <ul class="ul_menu" style="list-style: none">
      <li
        :class="{ topmenuactive: activeTab === 'ThietBiTab' }"
        @click="changeTab('ThietBiTab')"
      >
        <a class="urlThietBi">{{
          currentLanguage.header_text_thietBi_thietBi
        }}</a>
      </li>
      <li
        :class="{ topmenuactive: activeTab === 'DaiLy' }"
        @click="changeTab('DaiLy')"
      >
        <a class="urlDaiLy">{{ currentLanguage.header_text_thietBi_daiLy }}</a>
      </li>
    </ul>
  </nav> -->
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div class="wrapper row3">
        <main class="hoc container-fluid clear bv">
          <!-- gioi thieu -->
          <div
            v-show="showThietBiTab"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div id="giathietbi" class="tabcontent" style="display: block">
              <table cellspacing="0" border="0">
                <tbody class="tbody_table_css_tb">
                  <tr
                    style="
                      background: #85c8eb;
                      height: 30px;
                      line-height: 30px;
                      text-align: center;
                    "
                  >
                    <!-- <td class="td_tb_1_1">
                      <b>{{ currentLanguage.body_text_thietbi_tb_Ten }}</b>
                    </td> -->
                    <td
                      class="td_tb_2_1"
                      style="vertical-align: middle; text-align: center"
                    >
                      <b>{{
                        currentLanguage.body_text_thietbi_tb_Thietbibaohanh2nam
                      }}</b>
                    </td>
                    <td
                      class="td_tb_3_1"
                      style="vertical-align: middle; text-align: center"
                    >
                      <!-- <b>{{ currentLanguage.body_text_thietbi_tb_Ungdung }}</b> -->
                       <b> Nhà sản xuất</b>
                    </td>
                    <td
                      class="td_tb_4_1"
                      style="vertical-align: middle; text-align: center"
                    >
                      <b>{{
                        currentLanguage.body_text_thietbi_tb_Tinhnangkythuat
                      }}</b>
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_The2chip }}
                      </p>
                      <ul class="gallery flex">
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1697193212852l137F"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1697193212852l137F"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1697193308643RxMAF"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1697193308643RxMAF"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388318xmU7F"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388318xmU7F"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_Gia100000160000dongthe
                        }}<br />

                        {{
                          currentLanguage.body_text_thietbi_tb_Giataitro80000
                        }}
                      </p>

                      <p style="font-weight: normal">
                        {{
                          currentLanguage.body_text_thietbi_tb_Tangkembaodungthe
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <p>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1l332q03HuwZOjnuuIoFBJyZtCznYYVjo/view?usp=sharing"
                          >{{
                            currentLanguage.body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh
                          }}<br
                        /></a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=NXP_UCODE.pdf"
                        ></a>
                      </p>

                      <p>
                        <a
                          target="_blank"
                          href="https://www.nxp.com/company/about-nxp/investor-relations:INVESTOR-RELATIONS"
                          >{{
                            currentLanguage.body_text_thietbi_tb_Chitiettunhasanxuat
                          }}</a
                        >
                      </p>
                    </td>
                    <td class="td_tb_4_1">
                      <p>
                        <a
                          href="https://www.nxp.com/docs/en/data-sheet/MF3D_H_X3_SDS.pdf"
                          target="_blank"
                          >{{
                            currentLanguage.body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          href="https://www.nxp.com/assets/block-diagram/en/MF3DHx3.pdf"
                          target="_blank"
                          >{{
                            currentLanguage.body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://www.nxp.com/docs/en/fact-sheet/MFDFLFEV3.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB
                          }}</a
                        >
                      </p>

                      <p>
                        <a
                          target="_blank"
                          href="https://www.nxp.com/docs/en/fact-sheet/UCODE9FSA4.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_TailieukythuatveUCODE9
                          }}</a
                        >
                      </p>
                      <!-- <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=MF3DHx3_SDS-VN.pdf"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_TinhNangBaoMatEV3
                          }}</a
                        >
                      </p> -->
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_The3chipkyso }}
                      </p>
                      <ul class="gallery flex">
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670451wqXRF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670451wqXRF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17024546705456qkSF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17024546705456qkSF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388240eBSUF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388240eBSUF"
                              />
                            </a>
                          </li>
                        </ul>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_Gia150000210000dongthe
                        }}
                        <br />
                        {{
                          currentLanguage.body_text_thietbi_tb_Giataitro130000
                        }}
                      </p>
                      <p style="font-weight: normal">
                        {{
                          currentLanguage.body_text_thietbi_tb_Tangkembaodungthe
                        }}
                      </p>

                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <p>
                        <a
                          target="_blank"
                          :href="currentLanguage.body_text_thietbi_tb_LinkNXP"
                          >{{
                            currentLanguage.body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh
                          }}<br
                        /></a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=NXP_UCODE.pdf"
                        ></a>
                      </p>

                      <p>
                        <a target="_blank" href="https://www.acs.com.hk/en/">{{
                          currentLanguage.body_text_thietbi_tb_ChitiettunhasanxuatAdvanced
                        }}</a>
                      </p>
                    </td>
                    <td class="td_tb_4_1">
                      <p>
                        <a
                          href="https://www.nxp.com/assets/block-diagram/en/MF3DHx3.pdf"
                          target="_blank"
                          >{{
                            currentLanguage.body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          href="https://www.nxp.com/docs/en/data-sheet/MF3D_H_X3_SDS.pdf"
                          target="_blank"
                          >{{
                            currentLanguage.body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile
                          }}</a
                        >
                      </p>

                      <p>
                        <a
                          target="_blank"
                          href="https://www.nxp.com/docs/en/fact-sheet/MFDFLFEV3.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB
                          }}
                        </a>
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://www.nxp.com/docs/en/fact-sheet/UCODE9FSA4.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_TailieukythuatveUCODE9
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://www.acs.com.hk/en/products/308/acos5-64-v3_00-cryptographic-card-contact/"
                          >{{
                            currentLanguage.body_text_thietbi_tb_UngdungcuachipkysoACOS564
                          }}</a
                        >
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1" style="vertical-align: middle">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_VReader }} 1000
                      </p>
                      <ul
                        class="gallery flex"
                        style="display: flex; flex-wrap: wrap"
                      >
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1706781154552Gr2EF"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1706781154552Gr2EF"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT1850000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida1000mauvantay
                        }}
                      </p>

                      <p>{{ currentLanguage.body_text_thietbi_tb_Cambienquanghoc }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Thoigiannhandang }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_ip67 }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Thongso }}</p>
                      <p><a href="https://www.secukey.net/Product/Access_Control/Biometric_Access/139.html" target="_blank">https://www.secukey.net/Product/Access_Control/Biometric_Access/139.html</a></p>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1" style="vertical-align: middle">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_VReader }} 3000
                      </p>
                      <ul
                        class="gallery flex"
                        style="display: flex; flex-wrap: wrap"
                      >
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1706781154552Gr2EF"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1706781154552Gr2EF"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT2200000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida3000mauvantay
                        }}
                      </p>

                      <p>{{ currentLanguage.body_text_thietbi_tb_Cambienquanghoc }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Thoigiannhandang }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_ip67 }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Thongso }}</p>
                      <p><a href="https://www.secukey.net/Product/Access_Control/Biometric_Access/139.html" target="_blank">https://www.secukey.net/Product/Access_Control/Biometric_Access/139.html</a></p>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_TemUCODERFid }}
                      </p>
                      <div style="display: flex; justify-content: center">
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563900055xE8F"
                            >
                              <img
                                loading="lazy"
                                width="100px"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563900055xE8F"
                              />
                            </a>
                          </li>

                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356390083585uF"
                            >
                              <img
                                loading="lazy"
                                width="100px"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356390083585uF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356390161V2UyF"
                            >
                              <img
                                loading="lazy"
                                width="100px"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356390161V2UyF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT30000den60000dong
                        }}
                        <br />
                        {{
                          currentLanguage.body_text_thietbi_tb_Giataitro20000
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhmotnam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Kichthuocanten70x14mm
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_ChatlieuGiaytrang
                        }}
                      </p>
                    </td>

                    <td class="td_tb_4_1"> {{  currentLanguage.body_text_thietbi_tb_thongsokythuat }}

                      <p class="text_p">
                        - UCODE® 9 Accelerates the IoT <a target="_blank" href="https://www.nxp.com/products/SL3S1206FUD2"> (https://www.nxp.com/products/SL3S1206FUD2)</a>
                      </p> 
                       <p class="text_p"> - UCODE 8/8m  <a target="_blank" href="https://www.nxp.com/products/SL3S1205-15"> (https://www.nxp.com/products/SL3S1205-15)</a> 
                      </p> 
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_VPhone22 }}
                      </p>
                      <div class="d-flex">
                        <ul class="gallery flex">
                          <li>
                            <a target="_blank" href="img/thietbi/VP1.jpg">
                              <img loading="lazy" src="img/thietbi/VP1.jpg" />
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="img/thietbi/VP2.jpg">
                              <img loading="lazy" src="img/thietbi/VP2.jpg" />
                            </a>
                          </li>
                          <li>
                            <a target="_blank" href="img/thietbi/VP3.jpg">
                              <img loading="lazy" src="img/thietbi/VP3.jpg" />
                            </a>
                          </li>
                        </ul>
                        <div>
                          <!-- <a target="_blank" href="https://www_youtube_com/watch?v=zTV5_uhLTHA">
                                                <img loading="lazy" src="@routes_Assets_at("images/img_videoVphone_JPG}}">
                                            </a> -->
                        </div>
                      </div>

                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT3000000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiabantaitroVphone
                        }}
                      </p>
                  
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhmotnam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <div style="display: flex; width: 100%">
                        <div style="width: 50%">
                          <!-- <p>
                            <a
                              target="_blank"
                              href="https://vimass.vn/vmbank/services/media/viewFile?id=VPhone_22_GSM_Association.pdf"
                              >{{
                                currentLanguage.body_text_thietbi_tb_VPhone22GSMAssociation
                              }}</a
                            >
                          </p> -->
                          <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_ManHinh653inch19201200HD
                        }}
                      </p>
                          <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Front5MPBack13MP
                        }}
                      </p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_RAM3GB }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_nfc }}</p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Dungluongluutru32GB
                        }}
                      </p>
                          <p>
                            <a
                              target="_blank"
                              href="https://drive.google.com/file/d/1IX133wmVRB3FqkjRgyWespEghhIVktk3/view?usp=sharing"
                              >{{
                                currentLanguage.body_text_thietbi_tb_thongsokythuatVPhone22
                              }}</a
                            >
                          </p>
                          <!-- <p>
                            <a
                              target="_blank"
                              href="https://vimass.vn/vmbank/services/media/viewFile?id=MT6762D_LTE-A_Smartphone_Application_Processor_Technical_Brief_V1.pdf"
                              >{{
                                currentLanguage.body_text_thietbi_tb_MT6762DCPU
                              }}</a
                            >
                          </p>

                          <p>
                            <a
                              target="_blank"
                              href="https://vimass.vn/vmbank/services/media/viewFile?id=schematic_diagram_F699D_MT6762_V1.0_SCH.pdf"
                              >{{
                                currentLanguage.body_text_thietbi_tb_TechnicaldiagramMT6762
                              }}</a
                            >
                          </p>
                          <p>
                            <a
                              target="_blank"
                              href="https://vimass.vn/vmbank/services/media/viewFile?id=MTK6762_SC9863.pdf"
                              >{{
                                currentLanguage.body_text_thietbi_tb_CompareCPUMT6762DwithSC9863
                              }}</a
                            >
                          </p>
                          <p>
                            <a
                              target="_blank"
                              href="https://vimass.vn/vmbank/services/media/viewFile?id=User_Manual.pdf"
                              >{{
                                currentLanguage.body_text_thietbi_tb_UserManual
                              }}</a
                            >
                          </p> -->
                        </div>
                        <!-- <div style="width: 50%">
                          <p style="font-weight: 600">
                            {{
                              currentLanguage.body_text_thietbi_tb_MotsodienthoaicoNFC
                            }}
                          </p>
                          <p>
                            {{
                              currentLanguage.body_text_thietbi_tb_SamsungGalaxyM22
                            }}
                          </p>
                          <p>
                            {{
                              currentLanguage.body_text_thietbi_tb_SamsungGalaxyA32
                            }}
                          </p>
                          <p>
                            {{
                              currentLanguage.body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC
                            }}
                          </p>
                          <p>
                            {{
                              currentLanguage.body_text_thietbi_tb_SamsungGalaxyS6trolen
                            }}
                          </p>
                          <p>
                            {{
                              currentLanguage.body_text_thietbi_tb_IPhone7trolen
                            }}
                          </p>
                        </div> -->
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_VTablet22 }}
                      </p>
                      <div class="d-flex">
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671357NzwtF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671357NzwtF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563899276JHNF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563899276JHNF"
                              />
                            </a>
                          </li>
                        </ul>
                        <div>
                          <a
                            target="_blank"
                            href="https://www_youtube_com/watch?v=sT16PcTeoM4"
                          >
                            <!-- <img loading="lazy" src="@routes_Assets_at("images/img_videoVtalet_JPG}}"> -->
                          </a>
                        </div>
                      </div>

                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT3200000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiabantaitroVtab
                        }}
                      </p>
                
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhmotnam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_ManHinh101inch19201200HD
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Front5MPBack8MP
                        }}
                      </p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_RAM3GB }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_nfc }}</p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Dungluongluutru32GB
                        }}
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1us3jOBH7zwODn01IzITO96VWJr46CwW6/view?usp=sharing"
                          >{{
                            currentLanguage.body_text_thietbi_tb_ThongsokythuatVTablet22
                          }}</a
                        >
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                      
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_Maytinhmini7000000
                        }}
                      </p>
                      <div class="d-flex">
                        <ul class="gallery flex">
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670342GL0uF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670342GL0uF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563878651j8xF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563878651j8xF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563879588iHUF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563879588iHUF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_Gia7000000 }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4
                        }}
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_VGAIntelUHDGraphics
                        }}
                      </p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Ram8GB }}</p>
                      <p>{{ currentLanguage.body_text_thietbi_tb_Ocung2TB }}</p>
                      <p>
                        <a
                          target="_blank"
                          href="https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-11-essential-kit-atlas-canyon-celeron-n5105-bnuc11atkc40000?variantid=69490990"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc11essentialkitatlascanyonceleronn5105bnuc11atkc40000variantid69490990
                          }}</a
                        >
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{ currentLanguage.body_text_thietbi_tb_MaytinCPUI7 }}
                      </p>
                      <div class="d-flex">
                        <ul class="gallery flex">
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671263Y4OjF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671263Y4OjF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670248L6TcF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670248L6TcF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388052UavOF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388052UavOF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670154TSKcF"
                              target="_blank"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670154TSKcF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_Gia22374000 }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhbanam }}
                      </p>
                    </td>
                    <td colspan="2" class="td_tb_3_1">
                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/mainboard-asus-prime-b760m-a-wifi-d4?gclid=Cj0KCQjwoK2mBhDzARIsADGbjepePjhdgkY484rftjrNyFbrm2hs3krr0OC55YOhvkyP37d9WTLyAgYaAjz2EALw_wcB"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_MAINBOARD
                          }}</a
                        >
                      </p>

                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/cpu-intel-core-i7-12700f"
                          >{{
                            currentLanguage.body_text_thietbi_tb_CPUINTELCOREI7
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/tan-nhiet-khi-deepcool-ak400"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_TANNHIETKHI
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/ram-desktop-kingston-fury-rgb-kf432c16bb1ak2-32-32gb-2x16gb-ddr4-3200mhz?gclid=Cj0KCQjwoK2mBhDzARIsADGbjepKfkGJMIweh0DohWS-v56lCHZe7IymiVW9g-is8cAGN69w1g7eDisaAqWuEALw_wcB"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_RAMKINGTON
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://memoryzone.com.vn/ssd-kingston-nv2-m-2-pcie-gen4-x4-nvme-2tb-snv2s-2000g?variantId=75058432&utm_campaign=MMZ-other-shopping&utm_source=pmax&utm_medium=shopping&utm_content=all&gclid=Cj0KCQjwoK2mBhDzARIsADGbjeqsN1zlon2hvk6NhiMLOs6oJR8gk2sRQ5lyEjvTIUOyq2NJk9MUhwAaAsSmEALw_wcB"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_SSDKingston
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/nguon-may-tinh-cooler-master-mwe-v2-230v-750-750w-plus-bronze-den?gclid=Cj0KCQjwoK2mBhDzARIsADGbjeoo8AiYSdKTnSJAkJgd42qLrf_ZpRQa0ldnXbcuPi9jLJAq0xeE1r0aAq30EALw_wcB"
                        >
                          {{
                            currentLanguage.body_text_thietbi_tb_NGUONMAYTINH
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://hacom.vn/vo-case-kenoo-esport-fm800"
                          >{{ currentLanguage.body_text_thietbi_tb_VOCASE }}</a
                        >
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF
                        }}
                      </p>
                      <div style="display: flex; justify-content: center">
                        
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388146eBUqF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702356388146eBUqF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT2500000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhmotnam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <!-- <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=R16-7DB.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_thongsokythuat
                          }}</a
                        >
                      </p> -->

                      <p>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1O-pFpAqIckvn-tOUXZgN2uPGEwCNPPeA/view?usp=sharing"
                          >{{
                            currentLanguage.body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1mYhvUA0CAukfn4Rggb4t5c-rpAqtIcnA/view?usp=sharing"
                          >{{
                            currentLanguage.body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the
                          }}</a
                        >
                      </p>
                    </td>
                    <td class="td_tb_4_1"></td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF
                        }}
                      </p>
                      <div style="display: flex; justify-content: center">
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671170N9wFF"
                            >
                              <img
                                loading="lazy"
                                width="100px"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454671170N9wFF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT4400000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhmotnam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <!-- <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=R16-12dbi%20uhf%20rfid%20reader.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_thongsokythuatthietbithuphatUHF
                          }}</a
                        >
                      </p> -->
                      <p>
                        <a
                          target="_blank"
                          href="https://drive.google.com/file/d/1gLlAcoux03Z6pavBx-0mtzS2KsW0UTKe/view?usp=sharing"
                          >{{
                            currentLanguage.body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the
                          }}</a
                        >
                      </p>
                      <!-- <p>
                        <a
                          target="_blank"
                          href="https://vimass.vn/vmbank/services/media/viewFile?id=thong_so_ky_thuat_100_200tag_20m.pdf"
                          >{{
                            currentLanguage.body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the
                          }}</a
                        >
                      </p> -->
                    </td>

                    <td class="td_tb_4_1"></td>
                  </tr>

                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_ThekysoACSACOS564
                        }}
                      </p>
                      <div class="d-flex">
                        <ul class="gallery flex">
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670654JoWZF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670654JoWZF"
                              />
                            </a>
                          </li>

                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670748GGsbF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670748GGsbF"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670842OkysF"
                            >
                              <img
                                loading="lazy"
                                src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454670842OkysF"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT50000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1">
                      <p>
                        <a
                          target="_blank"
                          href="https://www.acs.com.hk/en/products/308/acos5-64-v3_00-cryptographic-card-contact/"
                          >{{
                            currentLanguage.body_text_thietbi_tb_UngdungcuachipkysoACOS564
                          }}
                        </a>
                      </p>
                      <p>
                        {{
                          currentLanguage.body_text_thietbi_tb_Chitiettunhasanxuat
                        }}
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://www.acs.com.hk/en/corporate-social-responsibility/"
                          >https://www.acs.com.hk/en/corporate-social-responsibility/</a
                        >
                      </p>
                      <p>
                        <a
                          target="_blank"
                          href="https://www.acs.com.hk/en/awards-recognitions/"
                          >https://www.acs.com.hk/en/awards-recognitions/</a
                        >
                      </p>
                    </td>
                    <td class="td_tb_4_1">
                      <p>
                        <a
                          href="https://drive.google.com/file/d/1LhacoWR7hT4K3nc0BG_xvDHlcElGmPFp/view?usp=sharingf"
                          target="_blank"
                          >-
                          {{
                            currentLanguage.body_text_thietbi_tb_Tinhnangkythuat
                          }}</a
                        >
                      </p>
                      <p>
                        <a
                          href="https://drive.google.com/file/d/1xTNk7ufuGLsjg60IZKdvmkO4cHM8Z072/view?usp=sharing"
                          target="_blank"
                          >{{
                            currentLanguage.body_text_thietbi_tb_Chinhsachbaomat
                          }}</a
                        >
                      </p>
                    </td>
                  </tr> 

                  <tr>
                    <!-- <td class="td_tb_1_1">
                     
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA
                        }}
                      </p>
                      <ul class="gallery flex">
                        <li>
                          <!-- <a target="_blank" href="@routes_Assets_at("images/daunoivimass4_jpg}}">
                                                <img loading="lazy" src="@routes_Assets_at("images/daunoivimass4_jpg}}">
                                            </a> -->
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454669857NE1KF"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454669857NE1KF"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT100000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1"></td>
                    <td class="td_tb_4_1"></td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                      
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe
                        }}
                      </p>
                      <ul class="gallery flex">
                        <li>
                          <!-- <a target="_blank" href="@routes_Assets_at("images/daunoivimass3_jpg}}">
                                                <img loading="lazy" src="@routes_Assets_at("images/daunoivimass3_jpg}}">
                                            </a> -->
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454669326lWTcF"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1702454669326lWTcF"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT100000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1"></td>
                    <td class="td_tb_4_1"></td>
                  </tr>
                  <tr>
                    <!-- <td class="td_tb_1_1">
                    
                    </td> -->

                    <td class="td_tb_2_1">
                      <p class="text_p" style="font-weight: 600">
                        {{
                          currentLanguage.body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM
                        }}
                      </p>
                      <ul class="gallery flex">
                        <li>
                          <!-- <a target="_blank" href="@routes_Assets_at("images/daunoivimass2_jpg}}">
                                                <img loading="lazy" src="@routes_Assets_at("images/daunoivimass2_jpg}}">
                                            </a> -->
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563868180ef6F"
                          >
                            <img
                              loading="lazy"
                              src="https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17023563868180ef6F"
                            />
                          </a>
                        </li>
                      </ul>
                      <p class="text_p">
                        {{
                          currentLanguage.body_text_thietbi_tb_GiagomVAT100000dong
                        }}
                      </p>
                      <p class="text_p">
                        {{ currentLanguage.body_text_thietbi_tb_baohanhhainam }}
                      </p>
                    </td>
                    <td class="td_tb_3_1"></td>

                    <td class="td_tb_4_1"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- tieu hoc-->
          <div
            id="DaiLy"
            v-show="showDaiLy"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleDaiLy"></div>
            <div v-html="contentDaiLy"></div>
          </div>
        </main>
      </div>
    </main>
  </div>
</template>
  <script>
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      activeTab: "ThietBiTab",

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showThietBiTab: true,
      showDaiLy: false,

      //active tab
      isActiveThietBiTab: true,
      isActiveDaiLy: false,

      //title
      titleDaiLy: "",

      //content
      contentDaiLy: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result_title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "DaiLy") {
          this.titleDaiLy = decodeURIComponent(escape(title));
          this.contentDaiLy = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    changeTab(tab) {
      this.lang = localStorage.getItem("languages");

      if (tab === "ThietBiTab") {
        this.isActiveThietBiTab = true;
        this.isActiveDaiLy = false;
      } else if (tab === "DaiLy") {
        this.isActiveDaiLy = true;
        this.isActiveThietBiTab = false;
        this.performPostRequest("1684299234996ruokw", "DaiLy", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showThietBiTab = tab === "ThietBiTab";
      this.showDaiLy = tab === "DaiLy";
    },
  },
};
</script>