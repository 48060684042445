<template>
   <header style="background-color: white;">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li></ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
        <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'gioiThieu' }"
          @click="changeTab('gioiThieu')"
        >
          <a id="urlCTThiDiem" style=" color: black;"
            >{{ currentLanguage.header_text_theDaNang_CTThiDiem }}
          </a>
        </li>

        <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'taiTro' }"
          @click="changeTab('taiTro')"
        >
          <a id="urlCTTaiTro" style=" color: black;"
            >{{ currentLanguage.footer_text_chuongTrinhTaiTro }}
          </a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'BenhVienTinh' }"
          @click="changeTab('BenhVienTinh')"
        >
          <a id="urlBenhVienTinh" style=" color: black;">{{
            currentLanguage.header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho
          }}</a>
        </li>
          <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'BenhVienHuyen' }"
          @click="changeTab('BenhVienHuyen')"
        >
          <a  id="urlBenhVienHuyen" style=" color: black;">{{
            currentLanguage.header_text_theDaNang_QuanHuyen
          }}</a>
        </li>
         <!-- <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'CoSoYTeXa' }"
          @click="changeTab('CoSoYTeXa')"
        >
          <a >{{
            currentLanguage.header_text_theDaNang_phoCap_boyte
          }}</a>
        </li>

          <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a >{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li> -->
      </ul>
    </nav>
   
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
   
          <!-- gioi thieu -->
          <div
            id="gioiThieu"
            v-show="showGioiThieu"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleGioiThieu"></div>
            <div v-html="contentGioiThieu"></div>
          </div>

           <!-- gioi thieu -->
           <div
            id="taiTro"
            v-show="showTaiTro"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleTaiTro"></div>
            <div v-html="contentTaiTro"></div>
          </div>
          <!-- tieu hoc-->
          <div
            id="BenhVienTinh"
            v-show="showBenhVienTinh"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleBenhVienTinh"></div>
            <div v-html="contentBenhVienTinh"></div>
          </div>
          <!-- BenhVienHuyen -->
          <div
            id="BenhVienHuyen"
            v-show="showBenhVienHuyen"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleBenhVienHuyen"></div>
            <div v-html="contentBenhVienHuyen"></div>
          </div>
          <!-- CoSoYTeXa -->
          <div
            id="CoSoYTeXa"
            v-show="showCoSoYTeXa"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleCoSoYTeXa"></div>
            <div v-html="contentCoSoYTeXa"></div>
          </div>

          <!-- LIEN HE -->
          <div
            id="lienhe"
            v-show="showLienHe"
            style="min-height: 500px; background-image: none !important"
          >
            <div class="title-bai-viet" v-html="titleLienHe"></div>
            <div v-html="contentLienHe"></div>
          </div>
       
    </main>
  </div>
  <!-- <FooterView></FooterView> -->
</template>
  <script>
// import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";
// import { vmCrop } from "@/components/axiosVM";

export default {
  components: {
    // FooterView,
  },
  data() {
    return {
      activeTab: "gioiThieu",
      isMenuVisible: false,
      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showGioiThieu: true,
      showBenhVienTinh: false,
      showBenhVienHuyen: false,
      showCoSoYTeXa: false,
      showLienHe: false,
      showTaiTro: false,

      //active tab
      isActiveGioiThieu: true,
      isActiveBenhVienTinh: false,
      isActiveBenhVienHuyen: false,
      isAcitveCoSoYTeXa: false,
      isActiveLienHe: false,
      isActiveTaiTro: false,

      //title
      titleGioiThieu: "",
      titleBenhVienTinh: "",
      titleBenhVienHuyen: "",
      titleCoSoYTeXa: "",
      titleLienHe: "",
      titleTaiTro: "",

      //content
      contentGioiThieu: "",
      contentBenhVienTinh: "",
      contentBenhVienHuyen: "",
      contentCoSoYTeXa: "",
      contentLienHe: "",
      contentTaiTro: "",

      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true;
      this.lang = localStorage.getItem("languages");
      const titleMap = {
        vi: "Vimass.vn - Y tế",
        en: "Vimass.vn - Medical",
        cn: "Vimass.vn - Medical",
      };
      document.title = titleMap[this.lang] || "Vimass.vn - Y tế";

      this.performPostRequest("17295687643578FvBH", "gioiThieu", this.lang);
      document.getElementById("urlCTThiDiem")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#benhvien/chuongtrinhthidiem");
        document.getElementById("urlCTTaiTro")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#benhvien/chuongtrinhtaitro");
        document.getElementById("urlBenhVienTinh")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#benhvien/benhvientinh");
        document.getElementById("urlBenhVienHuyen")
        .setAttribute("href", this.lang + "/ytegiaothongvadichvucong#benhvien/benhvienhuyen");
      const fragmentTab = window.location.hash.substring(1);
      console.log("fragmentTab "+ fragmentTab);
      
      if (fragmentTab === "benhvien/chuongtrinhthidiem") {
        this.changeTab("gioiThieu");
      } else if (fragmentTab === "benhvien/chuongtrinhtaitro") {
        this.changeTab("taiTro");
      } else if (fragmentTab === "benhvien/benhvientinh") {
        this.changeTab("BenhVienTinh");
      } else if (fragmentTab === "benhvien/benhvienhuyen") {
        this.changeTab("BenhVienHuyen");
      } else if (fragmentTab === "CoSoYTeXa") {
        this.changeTab("CoSoYTeXa");
      } else if (fragmentTab === "lienhe") {
        this.changeTab("lienhe");
      } else {
        // Nếu không phù hợp với bất kỳ tab nào, mặc định hiển thị tab gioiThieu
        this.changeTab("gioiThieu");
      }

     
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(
          apiChiTietBaiViet,
          {
            id: id,
            langId: "0",
          },
        );
        const data = response.data.result;
        this.base64 = data[`content_${this.lang}`];
        this.base64Title = data[`title_${this.lang}`];

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        }  else if (section === "taiTro") {
          this.titleTaiTro = decodeURIComponent(escape(title));
          this.contentTaiTro= decodeURIComponent(escape(decodedString));
        } else if (section === "BenhVienTinh") {
          this.titleBenhVienTinh = decodeURIComponent(escape(title));
          this.contentBenhVienTinh = decodeURIComponent(escape(decodedString));
        } else if (section === "BenhVienHuyen") {
          this.titleBenhVienHuyen = decodeURIComponent(escape(title));
          this.contentBenhVienHuyen = decodeURIComponent(escape(decodedString));
        } else if (section === "CoSoYTeXa") {
          this.titleCoSoYTeXa = decodeURIComponent(escape(title));
          this.contentCoSoYTeXa = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    changeTab(tab) {
      this.lang = localStorage.getItem("languages");
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      // Tạo một đối tượng để lưu trạng thái của các tab
      const tabStates = {
        gioiThieu: false,
        taiTro:false,
        BenhVienTinh: false,
        BenhVienHuyen: false,
        CoSoYTeXa: false,
        lienhe: false,
      };

      // Thiết lập trạng thái cho tab được chọn
      tabStates[tab] = true;

      // Gọi hàm performPostRequest với các tham số tương ứng
      this.performPostRequest(this.getPostId(tab), tab, this.lang);

      // Cập nhật giá trị activeTab
      this.activeTab = tab;

      // Cập nhật các biến show dựa trên trạng thái của các tab
      this.showGioiThieu = tabStates.gioiThieu;
      this.showTaiTro = tabStates.taiTro;
      this.showBenhVienTinh = tabStates.BenhVienTinh;
      this.showBenhVienHuyen = tabStates.BenhVienHuyen;
      this.showCoSoYTeXa = tabStates.CoSoYTeXa;
      this.showLienHe = tabStates.lienhe;
    },

    getPostId(tab) {
      // Sử dụng một đối tượng để ánh xạ tab với id của bài viết tương ứng
      const postIdMap = {
        gioiThieu: "17295687643578FvBH",
        taiTro: "1729568880082AB4AR",
        BenhVienTinh: "16944049354721oe4u",
        BenhVienHuyen: "1694405009668apaxz",
        CoSoYTeXa: "16914910788912yc0k",
        lienhe: "1690272053288yg3c7",
      };

      return postIdMap[tab];
    },
  },
};
</script>
  
  